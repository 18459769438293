import axios from '@axios'

const fetchAllCategories = queryParams => axios.get('/merchant/categories', { params: queryParams })

const storeCategory = data => axios.post('/merchant/categories', data)

const updateCategory = (id, data) => axios.put(`/merchant/categories/${id}`, data)

const destroyCategory = id => axios.delete(`/merchant/categories/${id}`)

const fetchAllTags = queryParams => axios.get('/merchant/tags', { params: queryParams })

const fetchAllMethods = queryParams => axios.get('/merchant/shipping-methods', { params: queryParams })

const storeTag = data => axios.post('/merchant/tags', data)

const updateTag = (id, data) => axios.put(`/merchant/tags/${id}`, data)

const destroyTag = id => axios.delete(`/merchant/tags/${id}`)

const storeSeo = (instanceType, instanceId, data) => axios.post(`/merchant/${instanceType}/${instanceId}/seos`, data)

const updateSeo = (instanceType, instanceId, id, data) => axios.put(`/merchant/${instanceType}/${instanceId}/seos/${id}`, data)

const storePostMedia = (url, data) => axios.post(`/merchant/post-media/${url}`, data)

const getCouriers = () => axios.get('/merchant/couriers')

const getCities = () => axios.get('/v1/cities')

const getDistricts = () => axios.get('/v1/districts')

export {
  fetchAllCategories,
  storeCategory,
  updateCategory,
  destroyCategory,
  fetchAllTags,
  fetchAllMethods,
  storeTag,
  updateTag,
  destroyTag,
  storeSeo,
  updateSeo,
  storePostMedia,
  getCouriers,
  getCities,
  getDistricts,
}
