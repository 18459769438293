import { mdiClockTimeEightOutline, mdiTimelapse } from '@mdi/js'

export default [
  {
    subheader: 'Appointment Management',
  },
  {
    title: 'Appointments',
    to: 'appointment-list',
    icon: mdiClockTimeEightOutline,
  },
  {
    title: 'History',
    to: 'appointment-history',
    icon: mdiTimelapse,
  },
]
