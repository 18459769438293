import { mdiAlphaSBox, mdiPackage } from '@mdi/js'

export default [
  {
    subheader: 'Product Management',
  },
  {
    title: 'Products',
    to: 'product-list',
    icon: mdiPackage,
  },
  {
    title: 'SKUs',
    to: 'sku-list',
    icon: mdiAlphaSBox,
  },
]
