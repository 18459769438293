import axios from '@axios'

export default {
  namespaced: true,
  state: {
    all: [], // in merchant portal sense, all = [self], this is used to map in shippment form
    branches: [],
    current: null, // current acting merchant
    policies: [], // current merchant's policies
    orderRemarks: [], // current merchant's order remarks
  },
  getters: {},
  mutations: {
    SET_MERCHANTS(state, all = []) {
      state.all = all
    },
    SET_BRANCHES(state, branches = []) {
      state.branches = branches
    },
    SET_CURRENT(state, merchant) {
      state.current = merchant
    },
    SET_POLICIES(state, policies) {
      state.policies = policies
    },
    SET_ORDER_REMARKS(state, orderRemarks) {
      state.orderRemarks = orderRemarks
    },
  },
  actions: {
    // fetchAllMerchants({ commit }, queryParams) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .get('/merchant/merchants', { params: queryParams })
    //       .then(response => {
    //         commit('SET_MERCHANTS', response.data.data.records)
    //         resolve(response)
    //       })
    //       .catch(error => reject(error))
    //   })
    // },

    setCurrent({ commit, dispatch }, merchant) {
      // const isSame = state.current && merchant && state.current.id === merchant.id

      // if (!isSame) {
      // }
      commit('SET_CURRENT', merchant)

      if (merchant) {
        commit('SET_MERCHANTS', [merchant])
        dispatch('fetchPolicies', merchant)
        dispatch('fetchOrderRemarks', merchant)
        dispatch('fetchBranches', merchant)
      }

      return Promise.resolve()
    },

    fetchPolicies({ commit, state }) {
      const params = { take: 999 }
      axios
        .get('/merchant/policies', { params })
        .then(response => {
          commit('SET_POLICIES', response.data.data.records)
        })
    },

    fetchOrderRemarks({ commit, state }) {
      const params = { take: 999 }
      axios
        .get('/merchant/order-remarks', { params })
        .then(response => {
          commit('SET_ORDER_REMARKS', response.data.data.records)
        })
    },

    fetchBranches({ commit, state }) {
      const params = { take: 999 }
      axios
        .get('/merchant/branches', { params })
        .then(response => {
          commit('SET_BRANCHES', response.data.data.records)
        })
    },

  },
}
