<template>
  <v-dialog
    v-if="visible && curNotif"
    v-model="visible"
    width="500"
    :persistent="curNotif.persistent || false"
    @click:outside="clickOutside"
  >
    <v-card>
      <v-card-title>
        {{ curNotif.title || 'Notification' }}
      </v-card-title>

      <v-card-text>
        <div v-html="curNotif.content"></div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="curNotif.rejectText"
          :loading="loading"
          color="error"
          @click="reject"
        >
          {{ curNotif.rejectText }}
        </v-btn>
        <v-btn
          :loading="loading"
          color="primary"
          @click="resolve"
        >
          {{ curNotif.resolveText || 'OK' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import appStore from '@/store/app'

export default {
  setup() {
    const visible = ref(true)
    const loading = ref(false)
    const curNotif = computed(() => appStore.state.notifications.find(e => !e.notified))

    const notified = () => {
      visible.value = false
      setTimeout(() => {
        curNotif.value.notified = 1
        visible.value = true
      }, 300)
    }

    const resolve = async () => {
      if (curNotif.value.resolve) {
        loading.value = true
        try {
          await curNotif.value.resolve()
        } catch (err) {
          console.log(err)
        }
        loading.value = false
      }
      notified()
    }

    const reject = async () => {
      if (curNotif.value.reject) {
        loading.value = true
        try {
          await curNotif.value.reject()
        } catch (err) {
          console.log(err)
        }
        loading.value = false
      }
      notified()
    }

    const clickOutside = () => {
      if (!curNotif.value.persistent) reject()
    }

    return {
      visible,
      loading,

      curNotif,
      notified,

      resolve,
      reject,

      clickOutside,

      isDialogVisible: true,
    }
  },
}
</script>
