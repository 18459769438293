const merchant = [
  {
    path: '/merchants',
    name: 'list-merchants',
    component: () => import('@/views/merchant/merchant-list/MerchantList.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/merchants/create',
    name: 'create-merchant',
    component: () => import('@/views/merchant/Create.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/merchant/view/:id',
    name: 'merchant-view',
    component: () => import('@/views/merchant/merchant-view/MerchantView.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default merchant
