import { fetchAllNotes } from '@/api/channel'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    channels: [],
    notes: [],
    channel: null, // current focused channel
  },
  getters: {},
  mutations: {
    SET_CHANNELS(state, chs = []) {
      state.channels = chs
    },
    SET_NOTES(state, notes = []) {
      state.notes = notes
    },
    SET_CHANNEL(state, ch = null) {
      state.channel = ch
    },
  },
  actions: {
    fetchAllChannels({ commit }, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/merchant/channels', { params: queryParams })
          .then(response => {
            commit('SET_CHANNELS', response.data.data.records)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    fetchAllNotes({ commit }, queryParams) {
      return fetchAllNotes(queryParams).then(response => {
        commit('SET_NOTES', response.data.data.records)

        return Promise.resolve(response)
      })
    },

    setChannel({ commit }, channel) {
      commit('SET_CHANNEL', channel)
    },
  },
}
