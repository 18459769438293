export default [
  {
    path: '/orders',
    name: 'order-list',
    component: () => import('@/views/checkout/order-list/OrderList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/orders/:id/overview',
    name: 'order-view',
    component: () => import('@/views/checkout/order-view/OrderView.vue'),
    meta: {
      layout: 'content',
    },
  },
]
