import {
  fetchAllCategories, fetchAllMethods, fetchAllTags, getCouriers
} from '@/api/common'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    categories: [],
    tags: [],
    methods: [],
    languages: [],
    couriers: [],
  },
  getters: {},
  mutations: {
    SET_CATEGORIES(state, cats = []) {
      state.categories = cats
    },
    SET_TAGS(state, tags = []) {
      state.tags = tags
    },
    SET_METHODS(state, methods = []) {
      state.methods = methods
    },
    SET_LANGUAGES(state, languages = []) {
      state.languages = languages
    },
    SET_COURIERS(state, couriers = []) {
      state.couriers = couriers
    },
  },
  actions: {
    fetchAllCategories({ commit }, queryParams) {
      // return new Promise((resolve, reject) => {
      //   axios
      //     .get('/admin/categories', { params: queryParams })
      //     .then(response => {
      //       commit('SET_CATEGORIES', response.data.data.records)
      //       resolve(response)
      //     })
      //     .catch(error => reject(error))
      // })
      return fetchAllCategories(queryParams).then(res => {
        commit('SET_CATEGORIES', res.data.data.records)

        return res
      })
    },

    fetchAllTags({ commit }, queryParams) {
      // return new Promise((resolve, reject) => {
      //   axios
      //     .get('/admin/tags', { params: queryParams })
      //     .then(response => {
      //       commit('SET_TAGS', response.data.data.records)
      //       resolve(response)
      //     })
      //     .catch(error => reject(error))
      // })
      return fetchAllTags(queryParams).then(res => {
        commit('SET_TAGS', res.data.data.records)

        return res
      })
    },

    fetchAllMethods({ commit }, queryParams) {
      return fetchAllMethods(queryParams).then(res => {
        commit('SET_METHODS', res.data.data.records)

        return res
      })
    },

    fetchAllLanguages({ commit }, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/merchant/languages', { params: queryParams })
          .then(response => {
            commit('SET_LANGUAGES', response.data.data.records)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    fetchCouriers({ commit }) {
      return getCouriers().then(res => {
        commit('SET_COURIERS', res.data.data.records)

        return res
      })
    },
  },
}
