export default [

  //
  //* ——— Reporting ——————————————————
  //

  {
    path: '/reporting/merchant-voucher-report',
    name: 'merchant-vouchers-report',
    component: () => import('@/views/reporting/merchant-vouchers-report/MerchantVoucherOverview.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/reporting/merchant-service-report',
    name: 'merchant-services-report',
    component: () => import('@/views/reporting/merchant-service-report/MerchantServiceOverview.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/reporting/merchant-tangible-report',
    name: 'merchant-tangibles-report',
    component: () => import('@/views/reporting/merchant-tangible-report/MerchantTangibleOverview.vue'),
    meta: {
      layout: 'content',
    },
  },
]
