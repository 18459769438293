export default [
  {
    path: '/appointments',
    name: 'appointment-list',
    component: () => import('@/views/appointment/list/List.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/appointment-history',
    name: 'appointment-history',
    component: () => import('@/views/appointment/list/History.vue'),
    meta: {
      layout: 'content',
    },
  },
]
