import { fetchAllAttributeTypes } from '@/api/product'
import axios from '@axios'
import unionBy from 'lodash/unionBy'

export default {
  namespaced: true,
  state: {
    brands: [],
    attributeTypes: [],
    attributeValues: [], // grouped as { attribute_type_id, records }
    allValues: [],
    natureOptions: [
      { title: 'Tangible', value: 'tangible' },
      { title: 'Service', value: 'service' },
      { title: 'Voucher', value: 'voucher' },
    ],
  },
  getters: {},
  mutations: {
    SET_BRANDS(state, brands) {
      state.brands = brands
    },
    SET_ATTRIBUTE_TYPES(state, attributeTypes) {
      state.attributeTypes = attributeTypes
    },
    SET_ATTRIBUTE_VALUES(state, updates) {
      const values = state.attributeValues.find(v => v.attribute_type_id === updates.attribute_type_id)
      if (values) {
        values.records = updates.records
      } else {
        state.attributeValues.push(updates)
      }
      state.allValues = unionBy(updates.records, state.allValues, 'id')
    },
  },

  actions: {
    fetchAllBrands({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/merchant/brands', { params: { take: 999 } })
          .then(response => {
            commit('SET_BRANDS', response.data.data.records)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    fetchAllAttributeTypes({ commit }, queryParams) {
      // return new Promise((resolve, reject) => {
      //   axios
      //     .get('/merchant/attribute-types', { params: { take: 999 } })
      //     .then(response => {
      //       commit('SET_ATTRIBUTE_TYPES', response.data.data.records)
      //       resolve(response)
      //     })
      //     .catch(error => reject(error))
      // })
      const params = { ...queryParams }
      params.take = 999

      return fetchAllAttributeTypes(params).then(res => {
        commit('SET_ATTRIBUTE_TYPES', res.data.data.records)

        return res
      })
    },

    // there are too many values. you must group by
    fetchAttributeValuesByType({ commit }, params) {
      return axios.get('/merchant/attribute-values', { params })
        .then(response => {
          commit('SET_ATTRIBUTE_VALUES', {
            attribute_type_id: params.attributeTypeId,
            records: response.data.data.records,
          })

          return Promise.resolve(response)
        })
    },
  },
}
