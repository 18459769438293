import axios from '@axios'

const fetchAllNotes = queryParams => axios.get('/merchant/channel-notes', { params: queryParams })

const storeNote = data => axios.post('/merchant/channel-notes', data)

const updateNote = (id, data) => axios.put(`/merchant/channel-notes/${id}`, data)

const destroyNote = id => axios.delete(`/merchant/channel-notes/${id}`)

export {

  fetchAllNotes, storeNote, updateNote, destroyNote,
}
