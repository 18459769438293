import { mdiCashMultiple } from '@mdi/js'

export default [
  {
    subheader: 'Order Management',
  },
  {
    title: 'Orders',
    to: 'order-list',
    icon: mdiCashMultiple,
  },
]
