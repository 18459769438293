export default [
  {
    subheader: 'Reporting',
  },

  {
    title: 'Vouchers',
    to: 'merchant-vouchers-report',
  },
  {
    title: 'Services',
    to: 'merchant-services-report',
  },
  {
    title: 'Tangibles',
    to: 'merchant-tangibles-report',
  },
]
